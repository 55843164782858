<template>
  <div class="topbar-item">
    <div
      class=" w-auto btn-clean d-flex align-items-center btn-lg px-2 user_profile_quick"
      id="kt_quick_user_toggle"
    >
      <span class="font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi,
      </span>
      <span class="font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ currentUser.u.first_name }}
      </span>
      <span class="symbol symbol-25 symbol-light-success">
        <img v-if="false" alt="Pic" :src="picture" />
        <span
          v-if="true"
          class="symbol-label font-weight-bold"
          style="font-size:0.9rem"
        >
          {{ currentUser.u.first_name | twoLetter }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    > 
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
          ref="menuclose"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <!-- <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="currentUser.u.avatar" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div> -->
          <div class="d-flex flex-column align-items-start">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUser.u.username }}
            </a>

            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <!-- <span class="navi-icon mr-1"> -->
                  <!-- <span class="svg-icon svg-icon-lg svg-icon-primary"> -->
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg -->
                  <!-- src="media/svg/icons/Communication/Mail-notification.svg" -->
                  <!-- /> -->
                  <!--end::Svg Icon-->
                  <!-- </span> -->
                  <!-- </span> -->
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.u.email }}
                  </span>
                </span>
              </a>
            </div>

            <button class="btn btn-light-primary btn-bold" @click="profile">
              Change Password
            </button>
            <button
              class="btn btn-light-primary btn-bold mt-2"
              @click="profile"
            >
              Profile
            </button>
            <button
              class="btn btn-light-primary btn-bold mt-2"
              @click="onLogout" v-if="!currentUser.i"
            >
              Sign out
            </button>
          </div>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user_profile_quick {
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #3f4f62;
    border-radius: 0 !important;
  }
  span {
    color: #fff;
    font-size: 14px;
    i {
      font-size: 14px;
    }
  }
  .symbol {
    span {
      color: black;
    }
  }
}
.offcanvas {
  width: 250px !important;
}
</style>

<script>
import { LOGOUT } from "../../../core/services/store/actions.type";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "../offcanvas/QuickPanel";
import { mapGetters } from "vuex";

export default {
  name: "KTQuickUser",
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        window.localStorage.removeItem("appoitmentsearch");
        this.$router.push({
          name: "client.login",
          params: { client_slug: this.$route.params.client_slug }
        });
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    profile() {
      if (this.currentUser.u.is_admin == 1) {
        this.$router.push({
          name: "admin.profile"
        });
      } else {
        this.$router.push({
          name: "client.profile",
          params: { client_slug: this.$route.params.client_slug }
        });
      }
      this.$refs.menuclose.click();
    }
  },

  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    ...mapGetters(["currentUser"])
  },
  filters: {
    twoLetter: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.charAt(1).toUpperCase();
    }
  }
};
</script>
